import React from 'react'
import { FaArrowRight } from "react-icons/fa6";
import { Link } from 'react-router-dom';



export default function Process(props) {
   const bookCalLink = 'https://cal.com/proyect/discovery'
  return (
    <section id='Process' className='home__process process'>
      <div className="process__container">
         <div className="process__section-block section-block">
            <div className="section-block__head">
               <h2 className="section-title">The Process</h2>
               <Link to={bookCalLink} target='_blank' className="button">
                  <div className="button__text">Book a Call</div>
                  <i className="button__icon">
                     <FaArrowRight />

                  </i>
               </Link>
            </div>
            <div className="section-block__body">
               <ul className="process__list">
                  <li className="process__item">
                     <div className='process__item-container'>
                        <i className='process__icon'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="45" height="42" viewBox="0 0 45 42" fill="none">
                              <path d="M44.0003 6.52732V0.258789H37.2756" stroke="#D5FF63" strokeWidth="0.5"/>
                              <path d="M1.00069 34.7315L1.00069 41H7.72542" stroke="#D5FF63" strokeWidth="0.5"/>
                              <path d="M43.8861 41.0001V11.9106C22.8144 15.9626 16.138 33.0217 15.4026 41.0001H43.8861Z" stroke="#D5FF63" strokeWidth="0.5"/>
                              <path d="M0.999628 -0.000127792L0.999628 29.0894C22.0713 25.0374 28.7478 7.97833 29.4832 -0.000127792L0.999628 -0.000127792Z" fill="#D5FF63"/>
                           </svg>
                        </i>
                        <div className='process__item-content'>
                           <div className="process__title title-sub-1">Subscribe</div>
                           <div className="process__text">Discover our subscription plans below, and feel free to schedule a <Link to={'https://cal.com/proyect/discovery'} target="_blank">call</Link> if you have any questions.</div>
                        </div>
                     </div>
                  </li>
                  <li className="process__item">
                     <div className='process__item-container'>
                        <i className='process__icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="68" height="38" viewBox="0 0 68 38" fill="none">
  <path d="M66.9969 1.00062H37.1786V37C38.0087 36.9474 36.7859 36.8737 42.8872 37C44.7418 21.1564 54.3075 15.3589 66.9969 10.9029L66.9969 1.00062Z" stroke="#D5FF63" strokeWidth="0.5"/>
  <path d="M-0.00279239 36.9994H29.8155V1C28.9855 1.05263 30.2083 1.12632 24.107 1C22.2524 16.8436 12.6866 22.6411 -0.00271442 27.0971L-0.00279239 36.9994Z" fill="#D5FF63"/>
</svg>
                        </i>
                        <div className='process__item-content'>
                           <div className="process__title title-sub-1">Add Tasks</div>
                           <div className="process__text">Meet the team, and pile on as many requests as you want, we're ready to jump in and start the process.</div>
                        </div>
                     </div>
                  </li>
                  <li className="process__item">
                     <div className='process__item-container'>
                        <i className='process__icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
  <path d="M38.6866 37.7436V23.1484V1.11939C37.2956 1.0293 32.8179 0.903161 26.0344 1.11939C23.9885 19.0666 8.49235 25.8058 1 26.932V37.7436H23.3427H38.6866Z" stroke="#D5FF63" strokeWidth="0.5"/>
  <path d="M38.6573 37.8516H23.3134V59.8806C24.7044 59.9707 29.1821 60.0968 35.9656 59.8806C38.0115 41.9334 53.5076 35.1942 61 34.068V23.2564H38.6573V37.8516Z" fill="#D5FF63"/>
  <path d="M24.1143 35.0719V23.5186H36.1291" stroke="#D5FF63" strokeWidth="0.5"/>
</svg>
                        </i>
                        <div className='process__item-content'>
                           <div className="process__title title-sub-1">Check it out</div>
                           <div className="process__text">Review as you move along, we'll keep things rolling with the next tasks to make sure you get the most out of your subscription.</div>
                        </div>
                     </div>
                  </li>
                  <li className="process__item">
                     <div className='process__item-container'>
                        <i className='process__icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="47" viewBox="0 0 49 47" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M48.7931 0.0488281H9.43506V28.5287L9.43594 28.5286V37.7242C10.0296 37.7237 11.1585 37.7239 12.8008 37.7241H12.8128C14.7813 37.7243 17.4852 37.7247 20.8867 37.7242C22.2746 22.1928 36.2688 16.0387 43.0934 14.9028V28.5287V37.7247H9.43506V37.7266H48.7931V0.0488281ZM43.0934 14.9028V5.7072C42.6217 5.70691 41.9717 5.70674 41.1147 5.70666H43.0942V14.9026L43.0934 14.9028ZM9.43594 28.5286V14.9026V5.70666H37.9138L31.6426 5.7072C30.2547 21.2386 16.2605 27.3927 9.43594 28.5286Z" fill="#D5FF63"/>
  <path d="M8.439 0.321289H0.8397C0.816226 8.26067 0.783364 25.0863 0.8397 28.8738H8.439" stroke="#D5FF63" strokeWidth="0.5"/>
  <path d="M48.5332 38.7265V46.3258C40.7855 46.3493 24.366 46.3822 20.6699 46.3258V38.7265" stroke="#D5FF63" strokeWidth="0.5"/>
</svg>
                        </i>
                        <div className='process__item-content'>
                           <div className="process__title title-sub-1">On to the next</div>
                           <div className="process__text">We quickly move on to the next task on your list, ensuring a smooth and efficient workflow.</div>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
            <div className='process__bottom'>
               <Link to={bookCalLink} target='_blank' className="button section-block__button">
                  <div className="button__text">Book a Call</div>
               </Link>
            </div>
         </div>
      </div>
    </section>
  )
}
