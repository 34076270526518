export const Menu = [
   'Process',
   'Services',
   'Work',
   'Pricing',
   'Benefits',
   'Privacy Policy / Terms',
   'FAQ',
]

export const rights = 'Proyect ©2024 All Rights Reserved'

export const Privicylist = [
   {
title: 'Information We Collect',
      id: 'privacy-sec-1',
   },
   {
title: 'Use of Your Information',
      id: 'privacy-sec-2',
   },
   {
title: 'Protection of Your Information',
      id: 'privacy-sec-3',
   },
   {
title: 'Disclosure of Your Information',
      id: 'privacy-sec-4',
   },
   {
title: 'Cookies and Similar Technologies',
      id: 'privacy-sec-5',
   },
   {
title: 'Your Rights',
      id: 'privacy-sec-6',
   },
   {
title: 'Changes to Our Privacy Policy',
      id: 'privacy-sec-7',
   },
   {
title: 'Contact Us',
      id: 'privacy-sec-8',
   },
]